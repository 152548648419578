import request from "@/plugins/axios";

// 运营
export const apiInformationOperational = (params: any) =>
    request.get("/statistics_information.information/operational", { params });

// 券销售
export const apiInformationSales = (params: any) =>
    request.get("/statistics_information.information/sales", { params });

// 服务
export const apiInformationRefund = (params: any) =>
    request.get("/statistics_information.information/refund", { params });

// 报表
export const apiOrderStatement = (params: any) =>
    request.get("/statistics_information.DataWin/orderStatement", { params });

// 报表
export const apiCategoryList = (params: any) =>
    request.get("/statistics_information.DataWin/categoryList", { params });

// 导出
export const apiExportData = (params: any) =>
    request.get("/statistics_information.DataWin/exportData", { params });

// 下载
export const apiDcenter = (params: any) =>
    request.get("/statistics_information.DataWin/Dcenter", { params });

/statistics_information.datawin/

export const apiDowntype= (params: any) =>
    request.get("/statistics_information.DataWin/downtype", { params });