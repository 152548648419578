






import * as echarts from "echarts/core";
import { PieChart, LineChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    GraphicComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
]);

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import LsDialog from "@/components/ls-dialog.vue";
import { isEmptyObject } from "@/utils/util";

@Component({
    components: {
        LsDialog,
    },
})
export default class resources_pie extends Vue {
    @Prop() data: any;

    @Watch("data", { immediate: true, deep: true })
    dataChange(value: any) {
        this.$nextTick(() => {
            this.inMallEchart(value);
        });
    }
    inMallEchart(data) {
        let D1 = 0;
        let D2 = 0;
        let myChart = echarts.init(this.$refs.inMall as any);
        if (data) {
            D1 = data.own_resources;
            D2 = data.platform_resource;
        }
        myChart.setOption({
            tooltip: {
                trigger: "item",
            },
            legend: {
                top: "0",
                left: "center",
            },
            series: [
                {
                    name: "供应链类型构成",
                    type: "pie",
                    radius: ["50%", "70%"],
                    avoidLabelOverlap: false,

                    label: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 14,
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: [
                        {
                            value: D1,
                            name: "自有资源",
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + "元";
                                },
                            },
                        },
                        {
                            value: D2,
                            name: "平台资源",
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + "元";
                                },
                            },
                        },
                    ],
                },
            ],
        });
    }

    created() {}

    mounted() {
        this.inMallEchart();
    }
}
