




































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import DatePicker from "@/components/date-picker.vue";
import order_consumption_line from "./components/order_consumption_line.vue";
import resources_pie from "./components/resources_pie.vue";
import goods_line from "./components/goods_line.vue";
import { apiInformationOperational } from "@/api/data_center";
import { timeFormat } from "@/utils/util";

@Component({
    components: {
        DatePicker,
        order_consumption_line,
        resources_pie,
        goods_line,
    },
})
export default class overview extends Vue {
    // S Data
    form: any = {
        start_time: "",
        end_time: "",
        type: "1",
    };
    buttonGroup = [
        {
            name: "日",
            type: "1",
        },
        {
            name: "周",
            type: "2",
        },
        {
            name: "月",
            type: "3",
        },
        {
            name: "年",
            type: "4",
        },
    ];
    // 订单
    order = {
        info: {},
        data: {},
    };
    loading = false
    order_tag = []
    // 商品分类
    goods_category = {};
    supplier = [];
    supply_chain = {
        platform_resource: "0",
        own_resources: "0",
    };
    
    // E Data
    changeDate(e) {
        this.form.start_time = e[0]
        this.form.end_time = e[1]
        this.getData(this.form.type);
    }
    // S Methods
    getData(type = 1) {
        this.loading = true;
        this.form.type = type;

        apiInformationOperational(this.form)
            .then((res) => {
                this.goods_category = res.category;
                this.order = {
                    info: res.order,
                    data: res.order_grid,
                };
                this.supplier = res.supplier;
                this.order_tag = res.order_tag;
                this.supply_chain = res.supply_chain;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }
    // E Methods

    // S  life cycle

    created() {
        let end = new Date();
        let start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        this.form.start_time = timeFormat(start, "yyyy-mm-dd");
        this.form.end_time = timeFormat(end, "yyyy-mm-dd");
        this.getData(1);
    }

    // E life cycle
}
