






import * as echarts from "echarts/core";
import { PieChart, LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    GraphicComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    LineChart,
    BarChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
]);

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import LsDialog from "@/components/ls-dialog.vue";
import { isEmptyObject } from "@/utils/util";

@Component({
    components: {
        LsDialog,
    },
})
export default class goods_line extends Vue {
    @Prop() data: any;

    @Watch("data", { immediate: true, deep: true })
    dataChange(value: any) {
        this.$nextTick(() => {
            this.goodsLineEchart(value);
        });
    }
    
    goodsLineEchart(data) {
        let xAxis = [];
        let D1 = [];
        let D2 = [];
        let myChart = echarts.init(this.$refs.goodsLine as any);
        if (data && !isEmptyObject(data)) {
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    const item = data[key];
                    xAxis.push(key);
                    D1.push(item.good_price);
                    D2.push(item.profit);
                }
            }
        }

        myChart.setOption({
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            legend: {
                left: "0",
                data: ["消费金额", "利润率"],
            },
            xAxis: [
                {
                    type: "category",
                    data: xAxis,
                },
            ],
            yAxis: [
                {
                    type: "value",
                    position: "left",
                    alignTicks: true,
                    axisLabel: {
                        formatter: function (value) {
                            return value / 10000 + "万";
                        },
                    },
                },
                {
                    type: "value",
                    position: "right",
                    alignTicks: true,
                    axisLabel: {
                        formatter: "{value} %",
                    },
                },
            ],
            series: [
                {
                    name: "消费金额",
                    type: "bar",
                    data: D1,
                    tooltip: {
                        valueFormatter: function (value) {
                            return value + "元";
                        },
                    },
                },

                {
                    name: "利润率",
                    type: "line",
                    yAxisIndex: 1,
                    data: D2,
                    tooltip: {
                        valueFormatter: function (value) {
                            return value + "%";
                        },
                    },
                },
            ],
        });
        // window.onresize = function () {
        //     echarts.resize();
        // };
    }

    created() {}

    mounted() {
        this.goodsLineEchart();
    }
}
